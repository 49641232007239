import fp from "fingerprintjs2";
import UAParser from "ua-parser-js";
import cookie from "react-cookie";

import {camelToSnake, UUID} from "../utils";
import {dataSourceV2} from "../DataSource";


const processUa = (ua, userAgent) => {
    const obj = {};
    if (userAgent) {
        obj.user_agent = userAgent;
    }

    obj.browser_name = (ua.browser || {}).name;
    obj.browser_version = (ua.browser || {}).version;
    obj.browser_major = (ua.browser || {}).major;
    obj.engine_name = (ua.engine || {}).name;
    obj.engine_version = (ua.engine || {}).version;
    obj.os_name = (ua.os || {}).name;
    obj.os_version = (ua.os || {}).version;
    obj.device_vendor = (ua.device || {}).vendor;
    obj.device_model = (ua.device || {}).model;
    obj.device_type = (ua.device || {}).type;
    obj.cpu_architecture = (ua.cpu || {}).architecture;
    return obj;
};

export const getUa = () => {
    if (window.navigator) {
        const ua = window.navigator.userAgent;
        let obj = UAParser(ua);
        obj = processUa(obj, ua);
        return obj;
    }
    return {};
};


const liteComponents = ["language", "colorDepth", "deviceMemory", "pixelRatio", "hardwareConcurrency", "screenResolution", "availableScreenResolution", "timezoneOffset", "timezone", "cpuClass", "platform", "webglVendorAndRenderer", "hasLiedLanguages", "hasLiedResolution", "hasLiedOs", "hasLiedBrowser", "touchSupport"];
const stableComponents = ["userAgent", "language", "colorDepth", "deviceMemory", "pixelRatio", "hardwareConcurrency", "availableScreenResolution", "timezoneOffset", "timezone", "sessionStorage", "localStorage", "indexedDb", "addBehavior", "openDatabase", "cpuClass", "platform", "doNotTrack", "plugins", "webglVendorAndRenderer", "hasLiedLanguages", "hasLiedResolution", "hasLiedOs", "hasLiedBrowser", "touchSupport", "fonts"];


const processFingerprint = (options) => {
    options = options || {};
    return new Promise(resolve => {
        fp.get({}, components => {
            const d = {};
            const x = (components || []).forEach(v => {
                if (v.key === "userAgent") {
                    const uaObj = UAParser(v.value);
                    delete uaObj["ua"];
                    d["ua"] = uaObj;
                }
                let key = camelToSnake(v.key);
                if (options.noLowercaseKey) {
                    key = v.key;
                }
                d[key] = v.value;

            });
            if (options.withHash) {
                const values = components.map(v => v.value);
                const hash = fp.x64hash128(values.join(""), 31);
                d["hash"] = hash;
            }
            if (options.withHashLite) {
                const values = components.filter(v => liteComponents.includes(v.key)).map(v => v.value);
                const hash = fp.x64hash128(values.join(""), 31);
                d["hash_lite"] = hash;
            }
            if (options.withHashStable) {
                const values = components.filter(v => stableComponents.includes(v.key)).map(v => v.value);
                const hash = fp.x64hash128(values.join(""), 31);
                d["hash_stable"] = hash;
            }
            resolve(d);
        });
    });
};

export const getFingerprint = (options) => {
    options = options || {};
    if (options.withIdle) {
        if (window.requestIdleCallback) {
            requestIdleCallback(() => processFingerprint(options));
        }
    }
    if (options.noTimeout) {
        return processFingerprint(options);
    }
    return new Promise(resolve => {
        setTimeout(resolve, options.timeout || 5000);
    }).then(() => processFingerprint(options));
};

export const preprocessDevice = (obj) => {
    const ua = obj.ua || {};
    delete obj["ua"];

    obj = {...obj, ...processUa(ua)};

    obj.screen_resolution_x = (obj.screen_resolution || [0, 0])[1];
    obj.screen_resolution_y = (obj.screen_resolution || [0, 0])[0];
    delete obj["screen_resolution"];

    obj.available_screen_resolution_x = (obj.available_screen_resolution || [0, 0])[1];
    obj.available_screen_resolution_y = (obj.available_screen_resolution || [0, 0])[0];
    delete obj["avaliable_screen_resolution"];

    obj.plugins = JSON.stringify(obj.plugins || []);
    obj.canvas = JSON.stringify(obj.canvas || []);
    obj.webgl = JSON.stringify(obj.webgl || []);

    obj.has_touch_support = !(obj.touch_support || []).toString().includes("false");
    obj.touch_support = JSON.stringify(obj.touch_support || []);

    obj.fonts = JSON.stringify(obj.fonts || []);

    obj.fingerprint_id = obj.hash;
    delete obj["hash"];
    obj.fingerprint_lite_id = obj.hash_lite;
    delete obj["hash_lite"];
    obj.fingerprint_stable_id = obj.hash_stable;
    delete obj["hash_stable"];
    return obj;
};


export const saveDevice = (obj, options) => {
    options = options || {};
    obj = preprocessDevice(obj);
    obj.ecommerce_store_id = options.ecommerce_store_id;
    obj.ecommerce_store_group_id = options.ecommerce_store_group_id;
    obj.site_id = options.ecommerce_store_group_id;
    return dataSourceV2(options.url || `device_update?device_id=${options.deviceSimpleId}`, {data: {update: obj}, url: options.apiPath})
                .then(v => {
                    if (!v.item_list || v.item_list.length === 0) {
                        dataSourceV2("device_create", {data: {item: {...obj, device_id: options.deviceSimpleId}}, url: options.apiPath});
                        return {item: {}};
                    }
                    const item = v.item_list[0];
                    if (item.device_id) {
                        cookie.save("d_id", item.device_id, {path: "/"});
                    }
                    if (item.fingerprint_id) {
                        cookie.save("f_id", item.fingerprint_id, {path: "/"});
                    }
                    if (item.fingerprint_lite_id) {
                        cookie.save("f_lite_id", item.fingerprint_lite_id, {path: "/"});
                    }
                    if (item.fingerprint_stable_id) {
                        cookie.save("f_stable_id", item.fingerprint_stable_id, {path: "/"});
                    }
                    return {item};
                });
};

export const cityMapping = {
    leningrad: "Санкт-Петербург",
    saratov: "Саратов",
    engels: "Энгельс",
    moscow: "Москва"
};

const preprocessIpMeta = (obj) => {
    const d = {
        ip_continent: obj.continent,
        ip_country: obj.country,
        ip_country_code: obj.country_code,
        ip_region: obj.region,
        ip_city: obj.city,
        ip_city_ru: cityMapping[(obj.city || "").toLowerCase()] || "",
        ip_name: obj.name,
        ip_type: obj.type,
        ip_isp: obj.isp,
        ip_lat: obj.lat,
        ip_lon: obj.lon,
        ip_org: obj.org,
        ip: obj.query
    };
    return d;
};

export const processIpMeta = (options) => {
    return dataSourceV2(options.url || "https://extreme-ip-lookup.com/json").then(obj => {
        let d = {};
        Object.keys(obj).forEach(key => {
            d[camelToSnake(key)] = obj[key];
        });
        d = preprocessIpMeta(d);
        return d;
    });
};

export const calculateDistance = (lat1, lon1, lat2, lon2, unit) => {
    lat1 = parseFloat(lat1);
    lon1 = parseFloat(lon1);
    lat2 = parseFloat(lat2);
    lon2 = parseFloat(lon2);
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344; }
		if (unit=="N") { dist = dist * 0.8684; }
		return dist;
	}
};

export const getIpMeta = (options) => {
    options = options || {};
    if (options.withTimeout) {
        return new Promise(resolve => {
            setTimeout(resolve, options.timeout || 5000);
        }).then(() => processIpMeta(options));
    }
    return processIpMeta(options);
};

export const processSimpleDeviceId = (options) => {
    options = options || {};
    const deviceSimpleId = "device_" + UUID();
    if (options.apiPath) {
        dataSourceV2("device_create", {data: {item: {device_id: deviceSimpleId}}, url: options.apiPath});
    }
    cookie.save("d_simple_id", deviceSimpleId, {path: "/"});
    return deviceSimpleId;
};

export const device = {
    device_id: cookie.load("d_id") || "",
    device_simple_id: cookie.load("d_simple_id")
};

export const deviceFunc = (options) => {
    const d = {
        device_id: cookie.load("d_id") || "",
        device_simple_id: cookie.load("d_simple_id") || processSimpleDeviceId(options)
    };
    return d;
};
